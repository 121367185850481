import { mergeAttributes, Mark, wrappingInputRule, markPasteRule } from '@tiptap/core'

const generateSafeHtmlId = () => `text-anchor-${Math.floor(Math.random() * 999)}`

export const inputRegex = /^\s*>\s$/
export const pasteRegex = /^\s*>\s/g
export const AnchorItem = Mark.create({
    name: 'anchorItem',
    content: 'block+',
    group: 'block',
    atom: true,

    addOptions() {
        return {
            HTMLAttributes: {
                class: 'editor__anchor'
            }
        }
    },

    addAttributes() {
        return {
            id: {
                default: null,
                parseHTML: element => element.getAttribute('data-id') || element.id,
                renderHTML: attributes => {
                    if (!attributes.id) {
                        return {}
                    }

                    return {
                        id: attributes.id,
                        'data-id': attributes.id
                    }
                }
            }
        }
    },

    renderHTML({ HTMLAttributes }) {
        return [
            'span',
            mergeAttributes(
                this.options.HTMLAttributes,
                HTMLAttributes
            ),
            0
        ]
    },

    parseHTML() {
        return [
            {
                tag: 'span.editor__anchor'
            }
        ]
    },

    addInputRules() {
        return [
            wrappingInputRule({
                find: inputRegex,
                type: this.type
            })
        ]
    },

    addPasteRules() {
        return [
            markPasteRule({
                find: pasteRegex,
                type: this.type
            })
        ]
    }
})
