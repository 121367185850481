<template>
    <content-view>
        <div
            v-if="isPageLoaded"
            class="knowledge-base__material"
            :class="{ 'knowledge-base__material--draft': !material.published }"
        >

            <div
                v-if="material.crumbs && material.crumbs.length > 1"
                class="material-crumbs"
                v-click-outside="onCrumbsMoreHide"
            >
                <div class="material-crumbs__header">
                    <div class="material-crumbs__row">
                        <div
                            v-for="(crumbsItem, index) in crumbsData.header"
                            :key="`crumbs-header-${index}`"
                            class="material-crumbs__col"
                        >
                            <router-link
                                v-if="crumbsItem.title !== 'ellipses'"
                                :to="`/knowledge/article/${crumbsItem.id}`"
                                class="material-crumbs__link"
                            >
                                {{ crumbsItem.title }}
                            </router-link>

                            <span
                                v-else
                                class="material-crumbs__link"
                            >
                                ...
                            </span>
                        </div>

                        <button
                            v-if="material.crumbs.length > 5"
                            class="material-crumbs__more"
                            type="button"
                            title="Ещё"
                            aria-label="More"
                            @click="onCrumbsMoreToggle"
                        ></button>
                    </div>
                </div>

                <perfect-scrollbar
                    v-show="isCrumbsMain"
                    class="material-crumbs__main"
                >
                    <div class="material-crumbs__row">
                        <div
                            v-for="(crumbsItem, index) in crumbsData.main"
                            :key="`crumbs-main-${index}`"
                            class="material-crumbs__col"
                        >
                            <router-link
                                :to="`/knowledge/article/${crumbsItem.id}`"
                                class="material-crumbs__link"
                            >
                                {{ crumbsItem.title }}
                            </router-link>
                        </div>
                    </div>
                </perfect-scrollbar>
            </div>

            <div class="material__control hide-print-css">
                <div class="material__control-group">
                    <!--                    <div class="material__btn">
                        <icon width="18" height="17" icon-color="#989CAE">
                            <path d="M18 8.51066L10.5195 0.748535V5.37956H8.92914C3.99765 5.37956 0 9.16251 0 13.8291V16.2833L0.706421 15.5508C3.10817 13.0606 6.50652 11.6418 10.0694 11.6418H10.5195V16.2728L18 8.51066Z"/>
                        </icon>
                    </div>-->
                    <div
                        class="material__btn material__btn--print"
                        ref="sharingButtonRef"
                        @click="sharingMaterial"
                        v-if='isAdmin'
                    >
                        <icon width="18" height="17" icon-color="#989CAE">
                            <path
                                d="M18 8.51066L10.5195 0.748535V5.37956H8.92914C3.99765 5.37956 0 9.16251 0 13.8291V16.2833L0.706421 15.5508C3.10817 13.0606 6.50652 11.6418 10.0694 11.6418H10.5195V16.2728L18 8.51066Z"
                                fill="#989CAE"
                            />
                        </icon>
                    </div>
                    <div
                        class="material__btn material__btn--print"
                        @click="printMaterial"
                    >
                        <icon width="18" height="17" icon-color="#989CAE">
                            <g clip-path="url(#clip0)">
                                <path
                                    d="M14.25 3.75V2.0625C14.25 0.92475 13.3253 0 12.1875 0H5.8125C4.67475 0 3.75 0.92475 3.75 2.0625V3.75H14.25Z"
                                    fill="#989CAE"
                                />
                                <path
                                    d="M15.9375 4.5H2.0625C0.92475 4.5 0 5.42475 0 6.5625V11.4375C0 12.5752 0.92475 13.5 2.0625 13.5H3V10.3125C3 9.58875 3.58875 9 4.3125 9H13.6875C14.4113 9 15 9.58875 15 10.3125V13.5H15.9375C17.0753 13.5 18 12.5752 18 11.4375V6.5625C18 5.42475 17.0753 4.5 15.9375 4.5Z"
                                    fill="#989CAE"
                                />
                                <path
                                    d="M13.6875 9.75H4.3125C4.002 9.75 3.75 10.002 3.75 10.3125V15.9375C3.75 17.0752 4.67475 18 5.8125 18H12.1875C13.3253 18 14.25 17.0752 14.25 15.9375V10.3125C14.25 10.002 13.998 9.75 13.6875 9.75ZM6.5625 12.375H7.6875C7.998 12.375 8.25 12.627 8.25 12.9375C8.25 13.248 7.998 13.5 7.6875 13.5H6.5625C6.252 13.5 6 13.248 6 12.9375C6 12.627 6.252 12.375 6.5625 12.375ZM10.6875 15.75H6.5625C6.252 15.75 6 15.498 6 15.1875C6 14.877 6.252 14.625 6.5625 14.625H10.6875C10.998 14.625 11.25 14.877 11.25 15.1875C11.25 15.498 10.998 15.75 10.6875 15.75Z"
                                    fill="#989CAE"
                                />
                            </g>
                            <defs>
                                <clipPath id="clip0">
                                    <rect width="18" height="18" fill="white" />
                                </clipPath>
                            </defs>
                        </icon>
                    </div>
                    <div
                        class="material__btn material__favourite"
                        @click="addToFavourites"
                    >
                        <icon
                            width="20"
                            height="20"
                            :icon-color="favouriteIconColor"
                        >
                            <path
                                d="M10 0L12.8331 6.10054L19.5106 6.90983L14.5841 11.4895L15.8779 18.0902L10 14.82L4.12215 18.0902L5.41591 11.4895L0.489435 6.90983L7.16687 6.10054L10 0Z"
                            />
                        </icon>
                    </div>
                </div>

                <div class="material__control-group">
                    <router-link
                        v-if="material.is_editor"
                        :to="`/knowledge/edit/${material.id}/`"
                        class="material__btn material__btn--edit"
                    >
                        <icon height="12" width="12" icon-color="#989CAE">
                            <path
                                d="M0 9.49953V11.9999H2.50035L9.87802 4.62217L7.37767 2.12183L0 9.49953Z"
                            />
                            <path
                                d="M11.5985 1.7519L10.0416 0.195027C9.78154 -0.065009 9.35813 -0.065009 9.0981 0.195027L7.87793 1.4152L10.3783 3.91554L11.5984 2.69537C11.8585 2.43534 11.8585 2.01193 11.5985 1.7519Z"
                            />
                        </icon>
                    </router-link>

                    <div
                        v-if="isMaterialDelete"
                        class="material__btn material__btn--delete"
                        @click="deleteMaterial"
                    >
                        <icon height="16" width="16" icon-color="#989CAE">
                            <g clip-path="url(#clip0)">
                                <path
                                    d="M13.2908 1.60494H10.4329V0.52175C10.4329 0.233625 10.1993 0 9.91111 0H6.08946C5.80133 0 5.56771 0.233594 5.56771 0.52175V1.60494H2.70974C2.42161 1.60494 2.18799 1.83853 2.18799 2.12669V3.73159C2.18799 4.01972 2.42158 4.25334 2.70974 4.25334H13.2908C13.5789 4.25334 13.8125 4.01975 13.8125 3.73159V2.12669C13.8126 1.8385 13.579 1.60494 13.2908 1.60494ZM9.38933 1.60494H6.61121V1.04347H9.38933V1.60494Z"
                                    fill="#989CAE"
                                />
                                <path
                                    d="M2.91211 5.29688L3.34473 15.5005C3.35655 15.7798 3.58645 16.0001 3.86598 16.0001H12.1337C12.4133 16.0001 12.6432 15.7798 12.655 15.5005L13.0876 5.29688H2.91211ZM6.42267 13.8784C6.42267 14.1665 6.18908 14.4001 5.90092 14.4001C5.6128 14.4001 5.37917 14.1665 5.37917 13.8784V7.41856C5.37917 7.13044 5.61277 6.89681 5.90092 6.89681C6.18905 6.89681 6.42267 7.13041 6.42267 7.41856V13.8784ZM8.52152 13.8784C8.52152 14.1665 8.28792 14.4001 7.99977 14.4001C7.71161 14.4001 7.47802 14.1665 7.47802 13.8784V7.41856C7.47802 7.13044 7.71161 6.89681 7.99977 6.89681C8.28792 6.89681 8.52152 7.13041 8.52152 7.41856V13.8784ZM10.6204 13.8784C10.6204 14.1665 10.3868 14.4001 10.0986 14.4001C9.81045 14.4001 9.57686 14.1665 9.57686 13.8784V7.41856C9.57686 7.13044 9.81045 6.89681 10.0986 6.89681C10.3868 6.89681 10.6204 7.13041 10.6204 7.41856V13.8784Z"
                                    fill="#989CAE"
                                />
                            </g>
                            <defs>
                                <clipPath id="clip0">
                                    <rect width="16" height="16" fill="white" />
                                </clipPath>
                            </defs>
                        </icon>
                    </div>
                </div>
            </div>

            <div class="material__meta hide-print-css">
                <div class="material__meta-row">
                    <div
                        v-if="material.is_editor && !material.published"
                        class="material__meta-group material__meta-draft"
                    >
                        <icon width="8" height="8" icon-color="#FBC04F">
                            <circle cx="4" cy="4" r="4" />
                        </icon>
                        <span class="material__meta-right">
                            Материал не опубликован
                        </span>
                    </div>

                    <div v-if="material.timepiece" class="material__meta-group">
                        <icon width="14" height="14" icon-color="#989CAE">
                            <path
                                d="M7 0C3.14005 0 0 3.14005 0 7C0 10.86 3.14005 14 7 14C10.86 14 14 10.86 14 7C14 3.14005 10.86 0 7 0ZM10.3291 10.6207C10.2153 10.7345 10.066 10.7917 9.9167 10.7917C9.76738 10.7917 9.61795 10.7345 9.5043 10.6207L6.5876 7.7041C6.47791 7.59505 6.4167 7.44679 6.4167 7.2917V3.5C6.4167 3.17743 6.67796 2.9167 7 2.9167C7.32204 2.9167 7.5833 3.17743 7.5833 3.5V7.0502L10.3291 9.7959C10.5571 10.024 10.5571 10.3927 10.3291 10.6207Z"
                            />
                        </icon>
                        <span class="material__meta-right">
                        {{ material.timepiece }}
                    </span>
                </div>
                <div class="material__meta-group" v-if="material.is_editor && material.permission_text">
                    <icon width="18" height="18" icon-color="#989CAE">
                        <g clip-path="url(#clip0)">
                            <path
                                d="M3 9C3.82843 9 4.5 8.32843 4.5 7.5C4.5 6.67157 3.82843 6 3 6C2.17157 6 1.5 6.67157 1.5 7.5C1.5 8.32843 2.17157 9 3 9Z"
                                fill="#989CAE"
                            />
                            <path
                                d="M5.0025 10.05C4.61324 10.343 4.29746 10.7226 4.08008 11.1586C3.86271 11.5947 3.74971 12.0753 3.75 12.5625V12.75H0.5625C0.255 12.75 0 12.495 0 12.1875V11.8125C0 10.6725 0.9225 9.75 2.0625 9.75H3.9375C4.3275 9.75 4.695 9.8625 5.0025 10.05Z"
                                fill="#989CAE"
                            />
                            <path
                                d="M15 9C15.8284 9 16.5 8.32843 16.5 7.5C16.5 6.67157 15.8284 6 15 6C14.1716 6 13.5 6.67157 13.5 7.5C13.5 8.32843 14.1716 9 15 9Z"
                                fill="#989CAE"
                            />
                            <path
                                d="M17.9996 11.8125V12.1875C17.9996 12.495 17.7446 12.75 17.4371 12.75H14.2496V12.5625C14.2496 11.535 13.7546 10.62 12.9971 10.05C13.3046 9.8625 13.6721 9.75 14.0621 9.75H15.9371C17.0771 9.75 17.9996 10.6725 17.9996 11.8125Z"
                                fill="#989CAE"
                            />
                            <path
                                d="M9 9.375C10.2426 9.375 11.25 8.36764 11.25 7.125C11.25 5.88236 10.2426 4.875 9 4.875C7.75736 4.875 6.75 5.88236 6.75 7.125C6.75 8.36764 7.75736 9.375 9 9.375Z"
                                fill="#989CAE"
                            />
                            <path
                                d="M11.0625 10.5H6.9375C6.39061 10.5004 5.86624 10.7178 5.47953 11.1045C5.09282 11.4912 4.8754 12.0156 4.875 12.5625V13.6875C4.875 13.998 5.127 14.25 5.4375 14.25H12.5625C12.7117 14.25 12.8548 14.1907 12.9602 14.0852C13.0657 13.9798 13.125 13.8367 13.125 13.6875V12.5625C13.1246 12.0156 12.9072 11.4912 12.5205 11.1045C12.1338 10.7178 11.6094 10.5004 11.0625 10.5Z"
                                fill="#989CAE"
                            />
                        </g>
                        <defs>
                            <clipPath id="clip0">
                                <rect width="18" height="18" fill="white" />
                            </clipPath>
                        </defs>
                    </icon>
                    <span class="material__meta-right">
                        {{ material.permission_text }}
                    </span>
                    </div>

                    <div
                        v-if="!material.is_editor && material.required"
                        class="material__meta-group"
                    >
                        <icon width="12" height="14" icon-color="#FBC04F">
                            <path
                                fill-rule="evenodd"
                                clip-rule="evenodd"
                                d="M0 0.5C0 0.223857 0.223858 0 0.5 0H11.1667C11.4428 0 11.6667 0.223858 11.6667 0.5V13.5C11.6667 13.7761 11.4428 14 11.1667 14H0.5C0.223858 14 0 13.7761 0 13.5V0.5ZM5.83333 8.16667C5.189 8.16667 4.66667 7.64433 4.66667 7V3.5C4.66667 2.85567 5.189 2.33333 5.83333 2.33333C6.47766 2.33333 7 2.85567 7 3.5V7C7 7.64433 6.47767 8.16667 5.83333 8.16667ZM5.83333 9.625C5.189 9.625 4.66667 10.1473 4.66667 10.7917C4.66667 11.436 5.189 11.9583 5.83333 11.9583C6.47767 11.9583 7 11.436 7 10.7917C7 10.1473 6.47767 9.625 5.83333 9.625Z"
                            />
                        </icon>
                        <span class="material__meta-right text-black">
                            Обязательно для изучения
                        </span>
                    </div>

                    <div
                        v-if="material.date && material.author"
                        class="material__meta-group"
                    >
                        <icon width="14" height="14" icon-color="#989CAE">
                            <path d="M11.375 0H5.25C4.55381 0 3.88613 0.276562 3.39384 0.768845C2.90156 1.26113 2.625 1.92881 2.625 2.625V4.375C2.625 4.60706 2.71719 4.82962 2.88128 4.99372C3.04538 5.15781 3.26794 5.25 3.5 5.25C3.73206 5.25 3.95462 5.15781 4.11872 4.99372C4.28281 4.82962 4.375 4.60706 4.375 4.375V2.625C4.375 2.39294 4.46719 2.17038 4.63128 2.00628C4.79538 1.84219 5.01794 1.75 5.25 1.75H11.375C11.6071 1.75 11.8296 1.84219 11.9937 2.00628C12.1578 2.17038 12.25 2.39294 12.25 2.625V8.75C12.25 8.98206 12.1578 9.20462 11.9937 9.36872C11.8296 9.53281 11.6071 9.625 11.375 9.625H9.625C9.39294 9.625 9.17038 9.71719 9.00628 9.88128C8.84219 10.0454 8.75 10.2679 8.75 10.5C8.75 10.7321 8.84219 10.9546 9.00628 11.1187C9.17038 11.2828 9.39294 11.375 9.625 11.375H11.375C12.0712 11.375 12.7389 11.0984 13.2312 10.6062C13.7234 10.1139 14 9.44619 14 8.75V2.625C14 1.92881 13.7234 1.26113 13.2312 0.768845C12.7389 0.276562 12.0712 0 11.375 0Z"/>
                            <path d="M0.875 11.375H2.625V13.125C2.625 13.3571 2.71719 13.5796 2.88128 13.7437C3.04538 13.9078 3.26794 14 3.5 14C3.73206 14 3.95462 13.9078 4.11872 13.7437C4.28281 13.5796 4.375 13.3571 4.375 13.125V11.375H6.125C6.35706 11.375 6.57962 11.2828 6.74372 11.1187C6.90781 10.9546 7 10.7321 7 10.5C7 10.2679 6.90781 10.0454 6.74372 9.88128C6.57962 9.71719 6.35706 9.625 6.125 9.625H4.375V7.875C4.375 7.64294 4.28281 7.42038 4.11872 7.25628C3.95462 7.09219 3.73206 7 3.5 7C3.26794 7 3.04538 7.09219 2.88128 7.25628C2.71719 7.42038 2.625 7.64294 2.625 7.875V9.625H0.875C0.642936 9.625 0.420376 9.71719 0.256282 9.88128C0.0921874 10.0454 0 10.2679 0 10.5C0 10.7321 0.0921874 10.9546 0.256282 11.1187C0.420376 11.2828 0.642936 11.375 0.875 11.375Z"/>
                        </icon>

                        <div class="material__meta-right">
                            Создано:
                            <span
                                class="material__meta-date"
                                :title="$moment(dateCreate).format('LLL')"
                            >
                                {{ dateCreate.local().fromNow() }},
                            </span>
                            <router-link
                                :to="`/auth/profile/${material.author.id}`"
                                :title="material.author.full_name"
                                target="_blank"
                                class="material__meta-author material__meta-author--link"
                            >{{ material.author.full_name }}</router-link>
                        </div>
                    </div>

                    <div
                        v-if="
                          dateChange.diff(this.dateCreate, 'seconds') > 50 &&
                          material.date_change &&
                          material.last_editor
                        "
                        class="material__meta-group"
                    >
                        <icon width="14" height="14" icon-color="#989CAE">
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M13.7145 1.6462L12.4444 0.369928C11.9536 -0.123309 11.1566 -0.123309 10.6641 0.369928L9.44749 1.59247L12.2954 4.45433L13.7145 3.02829C14.0952 2.64571 14.0952 2.02876 13.7145 1.6462ZM9.4465 1.60769L12.2944 4.46952L4.34456 12.4424L1.49827 9.58053L9.4465 1.60769ZM0.399972 13.9905C0.166692 14.0475 -0.0439032 13.8375 0.00792506 13.6031L0.727195 10.3554L3.57349 13.2173L0.399972 13.9905Z"/>
                        </icon>

                        <div class="material__meta-right">
                            Отредактировано:
                            <span
                                class="material__meta-date"
                                :title="$moment(dateChange).format('LLL')"
                            >
                                {{ $moment(dateChange).local().fromNow()  }},
                            </span>
                            <span
                                class="material__meta-author"
                                :title="material.last_editor"
                            >
                                {{ material.last_editor }}
                            </span>
                        </div>
                    </div>
                </div>
            </div>

            <company class="show-print-css" :domain="currentDomain" />

            <div class="material__content editor__content" id="page-print">
                <new-editor
                    class="content__editor"
                    :data="material.text"
                    :editable="false"
                    top-placeholder="Название материала"
                    bottom-placeholder="Описание материала"
                />
            </div>

            <div class="material__learning hide-print-css">
                <button
                    v-if="!material.is_editor"
                    class="v-btn mr-10 material__learning_button-green"
                    :class="{
                        'v-btn--ready': material.is_agree,
                        'v-btn--not-ready': !material.is_agree
                    }"
                    @click="getReady"
                >
                    <div class="material__learning_button-container">
                        <icon
                            width="15"
                            height="13"
                            class="material__learning_button-green-icon"
                        >
                            <svg
                                width="15"
                                height="13"
                                viewBox="0 0 15 13"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    d="M1 6.32895L5.30519 11L14 1"
                                    :stroke="
                                        !material.is_agree
                                            ? 'currentColor'
                                            : 'white'
                                    "
                                    stroke-width="2"
                                />
                            </svg>
                        </icon>
                        <span class="ml-10">Изучено</span>
                    </div>
                </button>
                <div
                    v-if="material.is_editor && studied.data.length"
                    class="material__studied"
                >
                    <div class="material__studied-container">
                        <img
                            class="material__studied-img"
                            v-for="staff in thirdStudied"
                            :src="staff.image_zoom_out || defaultAvatar"
                            alt="фото сотрудника"
                            :key="staff.id"
                        />
                    </div>
                    <button
                        class="material__studied-button"
                        @click="showStudiedStaff()"
                    >
                        {{ studied.text }}
                    </button>
                </div>
                <button
                    class="v-btn v-btn--red-bordered material__learning_button-red"
                    :class="{
                        'material__learning_button-red--ready':
                            material.suggestion
                    }"
                    @click="setOldModalOpen()"
                >
                    <div class="material__learning_button-container">
                        <icon
                            class="mr-10 material__learning_button-red-icon"
                            width="18"
                            height="18"
                        >
                            <svg
                                width="18"
                                height="18"
                                viewBox="0 0 18 18"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <circle
                                    cx="9"
                                    cy="9"
                                    r="8.25"
                                    stroke="currentColor"
                                    stroke-width="1.5"
                                />
                                <path
                                    d="M9 4L9 9.0625M9 10.75L9 13"
                                    stroke="currentColor"
                                    stroke-width="2.5"
                                />
                            </svg>
                        </icon>
                        <span>Устарело</span>
                    </div>
                </button>
                <button
                    v-if="material.is_editor && material.suggestion"
                    class="material__learning_button-counter"
                    @click="adminModalOpen()"
                >
                    {{ material.suggestion }}
                </button>
            </div>
            <div
                class="material__widgets hide-print-css"
                v-if="material.is_editor"
            >
                <questions-list
                    :material-id="materialId"
                    :material-title="material.title"
                />
            </div>
            <material-comments
                class="hide-print-css"
                :material-id="material.id"
                :comments="material.comments"
            />
        </div>
        <loading-spinner v-else />
    </content-view>
</template>

<script>
    import { mapGetters, mapState } from 'vuex'

    import ContentView from '@/views/menu/ContentView'
    import session from '@/api/session'
    import LoadingSpinner from '@/components/LoadingSpinner'
    import { metaTitle } from '@/mixins/meta_title'
    import defaultAvatar from 'assets/img/avatars/default_avatar.jpg'
    import { ValidateEditorVideo } from '../../mixins/validate-editor-video'
    import NewEditor from '@/components/editor/newEditor'

    export default {
        name: 'Material',
        mixins: [metaTitle],
        components: {
            NewEditor,
            QuestionsList: () =>
                import('@/components/knowledge_base/widgets/QuestionsList'),
            Company: () =>
                import('@/components/sidebar/header/companies/Company'),
            MaterialComments: () =>
                import('@/components/knowledge_base/MaterialComments'),
            Icon: () => import('@/components/icon/Icon'),
            ContentView,
            LoadingSpinner
        },
        data() {
            return {
                defaultAvatar,
                materialId: this.$route.params.material_id,
                material: {},
                studied: {},
                thirdStudied: [],
                description: '',
                isPageLoaded: false,
                isMaterialDelete: false,
                dateCreate: '',
                dateChange: '',
                crumbsData: {},
                isCrumbsMain: false
            }
        },
        computed: {
            ...mapGetters('default_data', [
                'isAdmin',
                'isEditorMaterial',
                'knowledgeBaseId',
                'current_user'
            ]),
            ...mapGetters('sidebar', ['currentDomain']),
            ...mapState('knowledge_base', ['prevAddedMaterialId']),
            setTitle() {
                return this.material ? this.material.title : ''
            },
            favouriteIconColor() {
                return this.material.is_favorite ? '#FBC04F' : '#989CAE'
            }
        },
        methods: {
            showStudiedStaff() {
                const StudiedStaffsModal = () =>
                    import(
                        '@/components/knowledge_base/modals/StudiedStaffsModal'
                    )
                this.$modal.show(
                    StudiedStaffsModal,
                    { staffs: this.studied },
                    {
                        name: 'studied-staffs',
                        adaptive: true,
                        maxWidth: 440,
                        width: '100%',
                        height: 'auto'
                    }
                )
            },
            setOldModalOpen() {
                const AddCommentModal = () =>
                    import('@/components/knowledge_base/modals/AddCommentModal')
                this.$modal.show(
                    AddCommentModal,
                    { material: this.material },
                    {
                        name: 'add-comment',
                        adaptive: true,
                        maxWidth: 440,
                        width: '100%',
                        height: 'auto'
                    }
                )
            },
            adminModalOpen() {
                const ShowCommentModal = () =>
                    import(
                        '@/components/knowledge_base/modals/ShowCommentModal'
                    )
                this.$modal.show(
                    ShowCommentModal,
                    { material: this.material },
                    {
                        name: 'show-comment',
                        adaptive: true,
                        maxWidth: 824,
                        width: '100%',
                        height: 'auto'
                    }
                )
            },
            async getReady() {
                if (!this.material.is_agree) {
                    await session.post(
                        `/api/v1/material/${this.materialId}/set_studied/`
                    )
                    this.material.is_agree = true
                    this.material.required = false
                    this.$store.dispatch('knowledge_base/editMaterial', {
                        ...this.material,
                        published: true
                    })
                }
            },
            sharingMaterial() {
                const rectCoord = this.$refs.sharingButtonRef.getBoundingClientRect()
                const windowWidth = document.documentElement.clientWidth
                const windowHeight = document.documentElement.clientHeight
                const pivotX = rectCoord.left / (windowWidth - 420)
                const pivotY = (rectCoord.bottom + 20) / windowHeight
                const SharingModal = () =>
                    import('@/components/knowledge_base/modals/SharingModal')
                this.$modal.show(
                    SharingModal,
                    { material: this.material },
                    {
                        name: 'sharing-material',
                        adaptive: true,
                        maxWidth: 420,
                        width: '100%',
                        height: 'auto',
                        pivotY,
                        pivotX
                    }
                )
            },
            printMaterial() {
                const lPage = document.getElementById('page-print')
                // let pageCls = lPage.getAttribute('class');
                // lPage.setAttribute('class', '');
                // let originalTitle = document.title;
                // document.title = "";
                // Print the page
                window.print({ iframe: false })

                // document.title = originalTitle;
                // lPage.prop('class', pageCls);
            },
            async addToFavourites() {
                try {
                    await session.post(
                        `/api/v1/material/${this.material.id}/set_favorite/`
                    )
                    this.material.is_favorite = !this.material.is_favorite
                } catch (err) {
                    this.errorHandler(err)
                }
            },
            async deleteMaterial() {
                const { material } = this
                this.$swal({
                    customClass: {
                        confirmButton: 'btn btn-lg btn-alt-success m-5',
                        cancelButton: 'btn btn-lg btn-alt-danger m-5'
                    },
                    title: 'Удалить материал?',
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonText: 'Да, удалить!',
                    cancelButtonText: 'Отменить'
                }).then(
                    value => {
                        if (value.value) {
                            const swal = this.$swal({
                                title: 'Удаление...',
                                icon: 'info',
                                showConfirmButton: false,
                                allowOutsideClick: false,
                                html: '<i class="fa fa-2x fa-cog fa-spin"></i>'
                            })
                            // выполнение
                            session
                                .delete(`/api/v1/material/${this.materialId}/`)
                                .then(response => {
                                    swal.close()
                                    if (parseInt(this.prevAddedMaterialId) === parseInt(this.materialId)) {
                                        this.$store.dispatch('knowledge_base/setPrevMaterialId', null)
                                    }
                                    this.$router.push(this.$route.query.from ? { name: this.$route.query.from } : '/knowledge')
                                    this.$store.dispatch(
                                        'knowledge_base/removeMaterial',
                                        {
                                            ...material,
                                            parent:
                                                material.parent ===
                                                this.knowledgeBaseId
                                                    ? this.knowledgeBaseId
                                                    : material.parent
                                        }
                                    )
                                })
                            /* .catch((err) => {
                                this.$swal({
                                    title: 'Ошибка!',
                                    text: 'При обработке запроса произошла ошибка на сервере',
                                    icon: 'error',
                                    showConfirmButton: false,
                                    timer: 1400,
                                });
                            }); */
                        }
                    },
                    reason => {
                        // отказ
                    }
                )
            },

            onCrumbsMoreToggle() {
                this.isCrumbsMain = !this.isCrumbsMain
            },

            onCrumbsMoreHide() {
                this.isCrumbsMain = false
            }
        },
        async mounted() {
            const { materialId } = this
            try {
                await session
                    .get(`/api/v1/material/${materialId}/studied/`)
                    .then(response => (this.studied = response.data))

                this.thirdStudied = this.studied.data.filter((studied, index) => index < 3)

                const request = session.get(`/api/v1/material/${materialId}/`)
                const response = await request

                response.data.text = ValidateEditorVideo(response.data.text)

                this.content = response.data.text
                this.material = response.data
                this.isMaterialDelete = this.material.is_editor

                if (this.current_user.is_admin === false || this.is_editor_material === false) {
                    const editorMaterialList = this.current_user.is_sub_editor_material

                    if (editorMaterialList !== false) {
                        const isExcludeMaterial = editorMaterialList.length > 0 ? editorMaterialList.find((id) => id == parseInt(materialId)) : undefined

                        this.material.is_editor = (isExcludeMaterial === undefined)
                        this.isMaterialDelete = false
                    }
                }

                this.dateCreate = this.$moment(this.material.date)
                this.dateChange = this.$moment(this.material.date_change, 'DD MMMM YYYY hh:mm:ss')

                // this.material.crumbs = [
                //     {
                //         id: 23,
                //         title: 'База знаний'
                //     },
                //     {
                //         id: 759,
                //         title: 'DevOps DevOps'
                //     },
                //     {
                //         id: 759,
                //         title: 'Тестирование'
                //     },
                //     {
                //         id: 23,
                //         title: 'Ещё один материал'
                //     },
                //     {
                //         id: 759,
                //         title: 'Название материала'
                //     },
                //     {
                //         id: 759,
                //         title: 'Родитель'
                //     }
                // ]

                // build crumbs
                if (this.material.crumbs && this.material.crumbs.length > 1) {
                    this.crumbsData.main = this.material.crumbs

                    if (this.material.crumbs.length > 5) {
                        this.crumbsData.header = this.material.crumbs.filter((elem, index, array) => index === 0 || index === array.length - 1)
                        this.crumbsData.header.splice(1, 0, { title: 'ellipses' })
                    } else {
                        this.crumbsData.header = this.material.crumbs
                    }

                    this.crumbsData.header.push({
                        id: materialId,
                        title: this.material.title
                    })
                }
                // /build crumbs

                this.isPageLoaded = true
            } catch (error) {
                this.$router.push('/404')
            }
        }
    }
</script>
<style lang="scss">
    @import '#sass/v-style';
    @import 'knowledge_base';

    $photo-size: 28px;
    $half-photo-size: $photo-size/2;

    .knowledge-base {
        &__material {
            @include below(1280px) {
                padding: 0 20px;
            }
            &--draft {
                .ProseMirror {
                    & > :first-child {
                        color: $text-lightgray !important;
                    }
                }
            }
            .material {
                &__studied {
                    display: flex;
                    margin-right: 12px;
                    &-container {
                        display: flex;
                        & > .material__studied-img + .material__studied-img {
                            margin-left: -$half-photo-size;
                        }
                        & > .material__studied-img {
                            width: $photo-size;
                            min-width: $photo-size;
                            height: $photo-size;
                            min-height: $photo-size;
                            overflow: hidden;
                            border-radius: 50%;
                            border: 2px solid #fff;
                            transition: border-color 200ms ease;
                        }
                    }
                    &-button {
                        margin-left: 12px;
                        border: none;
                        padding: 0;
                        cursor: pointer;
                        color: #2173d3;
                        background: transparent;
                        font-weight: 600;
                        font-size: 15px;
                        line-height: 20px;
                        max-height: 28px;
                        white-space: nowrap;
                        height: 28px;
                        transition: color 200ms ease;
                        &:hover {
                            color: rgb(8, 44, 87);
                        }
                    }
                }
                &__learning {
                    display: flex;
                    align-items: center;
                    overflow-x: auto;
                    overflow-y: hidden;
                    &_button {
                        &-counter {
                            cursor: pointer;
                            font-weight: 600;
                            margin-left: 12px;
                            border: none;
                            width: 40px;
                            min-width: 40px;
                            height: 40px;
                            background: #fbc04f;
                            border-radius: 50%;
                            overflow: hidden;
                            &:hover {
                                background: #fbb110;
                            }
                        }
                        &-green {
                            &-icon {
                                color: #50b440;
                            }
                            &:hover {
                                background: #219653;
                                color: #fff;
                                & .material__learning_button-green-icon {
                                    color: #fff;
                                }
                            }
                        }
                        &-red {
                            &--ready {
                                background: #ffa0a0;
                            }
                            &-icon {
                                color: #e32b2b;
                            }
                            &:hover {
                                background: #fecdcd;
                            }
                        }
                        &-container {
                            display: flex;
                            justify-content: center;
                            align-items: center;
                        }
                    }
                }
                &__control {
                    display: flex;
                    justify-content: space-between;
                    margin-top: 15px;
                    &-group {
                        display: flex;
                    }
                }
                &__widgets {
                    width: 100%;
                    display: flex;
                    margin-top: 50px;
                }
                &__widget {
                    padding: 30px;
                    border: 2px solid #e6ebf3;
                    border-radius: 4px;
                    width: 50%;
                    @include below(1000px) {
                        width: 100%;
                    }
                    .widget {
                        &__btn {
                            margin-top: 30px;
                        }
                    }
                }
                &__meta {
                    $pm: &;
                    margin-top: 50px;
                    @include below(600px) {
                        flex-direction: column;
                    }

                    &-row {
                        margin: -5px -10px;
                        display: flex;
                        flex-wrap: wrap;
                    }

                    &-group {
                        color: #989cae;
                        margin: 5px 10px;
                        display: flex;
                        align-items: center;
                        svg {
                            min-width: 14px;
                        }

                        @media screen and (min-width: $mobile) {
                            white-space: nowrap;
                        }
                    }

                    &-right {
                        margin-left: 10px;
                    }

                    &-draft {
                        color: #000;
                    }

                    &-date,
                    &-author {
                        color: $black;
                    }

                    &-author {
                        white-space: nowrap;
                        vertical-align: bottom;
                        text-overflow: ellipsis;
                        overflow: hidden;
                        max-width: 200px;
                        display: inline-block;
                        &--link {
                            text-decoration: underline;
                            &:hover {
                                text-decoration: none;
                            }
                        }
                    }
                }
                &__btn {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    height: 40px;
                    width: 40px;
                    border: 2px solid #e6ebf3;
                    border-radius: 50%;
                    &:hover {
                        cursor: pointer;
                        border-color: $gold;
                        svg,
                        path {
                            fill: black;
                        }
                    }
                    &:nth-child(n + 2) {
                        margin-left: 10px;
                    }
                }
                &__content {
                    margin-top: 30px;
                }
            }
        }
    }

    .material-crumbs {
        $bn: &;

        color: #999;
        position: relative;
        &__main {
            top: 35px;
            right: 0;
            left: 0;
            position: absolute;
            padding: 6px 10px;
            overflow-x: auto;
            border-radius: 4px;
            background-color: #fff;
            box-shadow: 0 1px 3px rgba(0,0,0,.1);
            .ps__rail-x,
            .ps__thumb-x {
                height: 6px !important;
            }
        }

        &__row {
            gap: 5px;
            display: flex;
            align-items: center;
        }

        &__col {
            gap: 5px;
            display: flex;
            align-items: center;
            & + .material-crumbs__col {
                &:before {
                    content: '•';
                    display: block;
                }
            }

            #{$bn}__header & {
                @media screen and (max-width: $mobile) {
                    display: none;

                    &:first-of-type,
                    &:nth-last-child(2),
                    &:last-of-type {
                        display: flex;
                    }

                    &:first-of-type {
                        &:before {
                            content: '•';
                            display: block;
                            order: 1;
                        }

                        &:after {
                            content: '…';
                            display: block;
                            order: 1;
                        }
                    }
                }
            }
        }

        &__link {
            overflow: hidden;
            display: inline-block;
            white-space: nowrap;
            text-overflow: ellipsis;
            #{$bn}__header & {
                max-width: 480px;
                @media screen and (max-width: $desktop) {
                    max-width: 140px;
                }
            }
        }

        &__more {
            cursor: pointer;
            display: flex;
            align-items: center;
            justify-content: center;
            width: 30px;
            height: 30px;
            border: 0px;
            background-color: transparent;
            &:before {
                width: 8px;
                height: 8px;
                border-width: 0 2px 2px 0;
                border-style: solid;
                border-color: #b5b5b5;
                transform: rotate(45deg);
                content: "";
                display: block;
            }

            &:hover {
                &:before {
                    border-color: $gold;
                }
            }
        }
    }
</style>
