<template>
    <node-view-wrapper :class="isImage ? 'document__image' : 'document__card'" ref="documentRef">
        <img v-if="isImage" :src="attachment.link" :alt="attachment.file_name">
        <button
            v-else
            type="button"
            class="document__card-btn"
            @click="showPreviewModal()"
        >
            <div class="document__card-image">
                <img :src="getIcon" />
            </div>
            <div class="document__card-text">
                <p class="card-text__title">{{ attachment.file_name }}</p>
                <p class="card-text__size">{{ formatBytes }}</p>
            </div>
        </button>
        <button
            type="button"
            class="attachment__delete"
            @click="deleteDocument()"
            v-if="editor.view.editable"
        >
            <icon height="16" width="16" icon-color="#989CAE">
                <g clip-path="url(#clip0)">
                    <path
                        d="M13.2908 1.60494H10.4329V0.52175C10.4329 0.233625 10.1993 0 9.91111 0H6.08946C5.80133 0 5.56771 0.233594 5.56771 0.52175V1.60494H2.70974C2.42161 1.60494 2.18799 1.83853 2.18799 2.12669V3.73159C2.18799 4.01972 2.42158 4.25334 2.70974 4.25334H13.2908C13.5789 4.25334 13.8125 4.01975 13.8125 3.73159V2.12669C13.8126 1.8385 13.579 1.60494 13.2908 1.60494ZM9.38933 1.60494H6.61121V1.04347H9.38933V1.60494Z"
                        fill="currentColor"
                    />
                    <path
                        d="M2.91211 5.29688L3.34473 15.5005C3.35655 15.7798 3.58645 16.0001 3.86598 16.0001H12.1337C12.4133 16.0001 12.6432 15.7798 12.655 15.5005L13.0876 5.29688H2.91211ZM6.42267 13.8784C6.42267 14.1665 6.18908 14.4001 5.90092 14.4001C5.6128 14.4001 5.37917 14.1665 5.37917 13.8784V7.41856C5.37917 7.13044 5.61277 6.89681 5.90092 6.89681C6.18905 6.89681 6.42267 7.13041 6.42267 7.41856V13.8784ZM8.52152 13.8784C8.52152 14.1665 8.28792 14.4001 7.99977 14.4001C7.71161 14.4001 7.47802 14.1665 7.47802 13.8784V7.41856C7.47802 7.13044 7.71161 6.89681 7.99977 6.89681C8.28792 6.89681 8.52152 7.13041 8.52152 7.41856V13.8784ZM10.6204 13.8784C10.6204 14.1665 10.3868 14.4001 10.0986 14.4001C9.81045 14.4001 9.57686 14.1665 9.57686 13.8784V7.41856C9.57686 7.13044 9.81045 6.89681 10.0986 6.89681C10.3868 6.89681 10.6204 7.13041 10.6204 7.41856V13.8784Z"
                        fill="currentColor"
                    />
                </g>
                <defs>
                    <clipPath id="clip0">
                        <rect width="16" height="16" fill="white" />
                    </clipPath>
                </defs>
            </icon>
        </button>
    </node-view-wrapper>
</template>

<script>
    import { NodeViewWrapper, nodeViewProps } from '@tiptap/vue-2'
    import DocIcon from '@/assets/img/document_types/doc.svg'
    import XlsIcon from '@/assets/img/document_types/xls.svg'
    import PptIcon from '@/assets/img/document_types/ppt.svg'
    import PdfIcon from '@/assets/img/document_types/pdf.svg'
    import UndefinedIcon from '@/assets/img/document_types/undefined.svg'
    import session from '@/api/session'
    import Icon from '@/components/icon/Icon'
    import {mapState} from "vuex";

    export default {
        name: 'Document',
        components: {
            NodeViewWrapper,
            Icon
        },
        data() {
            return {
                materialId: this.$route.params.material_id,
                attachment: { file_name: '', file_size: 0 },
                DocIcon,
                PdfIcon,
                XlsIcon,
                PptIcon,
                UndefinedIcon,
                imgExtensions: [
                    'jpg',
                    'jpeg',
                    'png',
                    'gif',
                    'svg',
                    'webp',
                    'avif',
                    'apng',
                    'tif',
                    'pjp',
                    'xbm',
                    'jxl',
                    'svgz',
                    'ico',
                    'tiff',
                    'jfif',
                    'bmp',
                    'jpeg',
                    'avif'
                ]
            }
        },
        // props: ['node', 'editor'],
        props: nodeViewProps,
        computed: {
            ...mapState('default_data', ['newCourseId']),
            isImage() {
                const { extension } = this.attachment
                return this.imgExtensions.includes(extension)
            },
            getIcon() {
                const { extension } = this.attachment
                if (extension === 'doc' || extension === 'docx') {
                    return this.DocIcon
                }
                if (extension === 'xls' || extension === 'xlsx') {
                    return this.XlsIcon
                }
                if (extension === 'ppt' || extension === 'pptx') {
                    return this.PptIcon
                }
                if (extension === 'pdf') {
                    return this.PdfIcon
                }
                return this.UndefinedIcon
            },
            formatBytes() {
                const bytes = this.attachment.file_size
                const decimals = 0
                if (bytes === 0) return '0 Байт'
                const k = 1024
                const dm = decimals < 0 ? 0 : decimals
                const sizes = [
                    'Байт',
                    'Кб',
                    'Мб',
                    'Гб',
                    'Тб',
                    'Пб',
                    'Еб',
                    'Зб',
                    'Йб'
                ]
                const i = Math.floor(Math.log(bytes) / Math.log(k))
                return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${
                    sizes[i]
                }`
            }
        },
        async created() {
            await session
                .get(
                    `/api/v1/${this.$route.params.lesson_id
                    ? 'course-lesson/'
                    : !this.$route.params.lesson_id && (this.$route.params.course_id || this.newCourseId)
                        ? 'course/'
                        : 'material/' }${!this.$route.params.lesson_id && (this.$route.params.course_id || this.newCourseId)
                            ? this.$route.params.course_id || this.newCourseId
                            : this.$route.params.lesson_id
                                ? this.$route.params.lesson_id
                                : this.$route.params.material_id}/attachment/${
                        this.node.attrs.id
                    }/${
                        this.$route.query.sharing
                            ? `?sharing=${this.$route.query.sharing}`
                            : ''
                    }`
                )
                .then(
                    response =>
                        (this.attachment = {
                            ...response.data,
                            extension: response.data.file_name.split('.').pop()
                        })
                )
                .catch(error => console.error(error))
        },
        methods: {
            showPreviewModal() {
                const DocumentPreviewModal = () =>
                    import('@/components/editor/modals/DocumentPreviewModal')
                this.$modal.show(
                    DocumentPreviewModal,
                    { attachment: this.attachment },
                    {
                        name: 'document-preview',
                        adaptive: true,
                        maxWidth: 700,
                        width: '100%',
                        height: 'auto'
                    }
                )
            },
            async deleteDocument(e) {
                this.$swal({
                    customClass: {
                        confirmButton: 'btn btn-lg btn-alt-success m-5',
                        cancelButton: 'btn btn-lg btn-alt-danger m-5'
                    },
                    title: 'Удалить документ?',
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonText: 'Да, удалить!',
                    cancelButtonText: 'Отменить'
                }).then(
                    value => {
                        if (value.value) {
                            const swal = this.$swal({
                                title: 'Удаление...',
                                icon: 'info',
                                showConfirmButton: false,
                                allowOutsideClick: false,
                                html: '<i class="fa fa-2x fa-cog fa-spin"></i>'
                            })
                            // выполнение
                            session
                                .delete(
                                    `/api/v1/${this.$route.params.lesson_id ? 'course-lesson/' : !this.$route.params.lesson_id && this.$route.params.course_id ? 'course/': 'material/' }${!this.$route.params.lesson_id && this.$route.params.course_id ? this.$route.params.course_id : this.$route.params.lesson_id ? this.$route.params.lesson_id : this.$route.params.material_id}/attachment/${this.node.attrs.id}/`
                                )
                                .then(response => {
                                    swal.close()
                                    this.$refs.documentRef.$el.remove()
                                })
                                .catch(err => {
                                    err.response.status === 404
                                        ? (this.$refs.documentRef.$el.remove(),
                                          swal.close())
                                        : this.$swal({
                                              title: 'Ошибка!',
                                              text:
                                                  'При обработке запроса произошла ошибка на сервере',
                                              icon: 'error',
                                              showConfirmButton: false,
                                              timer: 1400
                                          })
                                })
                        }
                    },
                    reason => {
                        // отказ
                    }
                )
            }
        }
    }
</script>

<style lang='scss' scoped>
    $gray: #dbdbdb;
    $icon-gray: #cccccc;
    $red: #e30000;

    .document__image {
        position: relative;
        width: 100%;
        height: 100%;
        border-radius: 4px;
        overflow: hidden;
        display: inline-flex;
        justify-content: center;

        img {
            width: auto;
            height: auto;
            max-height: 100%;
            max-width: 100%;
            object-fit: contain;
        }

        .attachment__delete {
            position: relative;
            top: 10px;
            right: 30px;
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 5px;
            width: 20px;
            height: 20px;
            border: 1px solid #dbdbdb;
            border-radius: 50%;
            color: #cccccc;
            background: #fff;
            transition: border-color 0.15s ease-in-out, color 0.15s ease-in-out;
            cursor: pointer;

            &:hover {
                border-color: $red;
                color: $red;
            }
        }
    }

    .document__card {
        position: relative;
        width: 300px;
        height: 80px;
        box-shadow: 0 3px 12px rgba(0, 0, 0, 0.1);
        border-radius: 4px;
        overflow: hidden;
        background: #fff;

        &-image {
            flex-shrink: 0;
            margin-right: 10px;
        }

        &-text {
            display: flex;
            flex-direction: column;
        }

        &:hover {
            box-shadow: 0 3px 12px rgba(0, 0, 0, 0.15);
        }

        &:active {
            box-shadow: inset 0 2px 8px rgba(0, 0, 0, 0.05);
        }

        &-btn {
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            background: transparent;
            border: none;
            cursor: pointer;
            display: flex;
            padding: 10px;
        }

        .attachment__delete{
            position: absolute;
            top: 8px;
            right: 8px;
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 5px;
            width: 20px;
            height: 20px;
            border: 1px solid $gray;
            border-radius: 50%;
            color: $icon-gray;
            background: #fff;
            transition: border-color 0.15s ease-in-out, color 0.15s ease-in-out;
            cursor: pointer;

            &:hover {
                border-color: $red;
                color: $red;
            }
        }

        &::before {
            content: none !important;
        }
    }

    .card-text {
        display: flex;
        flex-direction: column;
        align-items: flex-start;

        &__title {
            margin: 0 !important;
            font-size: 14px;
            line-height: 20px;
            color: #000000;
        }

        &__size {
            margin: 4px 0 0 0 !important;
            font-size: 12px;
            line-height: 16px;
            color: #989cae;
            text-align: left;
        }
    }
</style>
