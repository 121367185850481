import { Node } from '@tiptap/core'
import { VueNodeViewRenderer } from '@tiptap/vue-2'
import { Plugin } from 'prosemirror-state'

import DocumentComponent from '../component/Document'

export const Document = Node.create({
    name: 'document',
    group: 'block',
    atom: true,
    selectable: false,
    addAttributes() {
        return {
            id: {
                default: null
            }
        }
    },

    parseHTML() {
        return [{
            tag: 'document'
        }]
    },

    renderHTML({ HTMLAttributes }) {
        return [
            'document',
            HTMLAttributes
        ]
    },

    schema() {
        return {
            attrs: {
                id: {
                    default: null
                }
            },
            group: 'block',
            selectable: false,
            locked: true,
            parseDOM: [
                {
                    tag: 'document',
                    getAttrs: dom => {
                        return {
                            id: dom.getAttribute('id')
                        }
                    }
                }
            ],
            toDOM: node => [
                'document',
                {
                    id: node.attrs.id
                }
            ]
        }
    },

    addProseMirrorPlugins() {
        return [
            new Plugin({
                props: {
                    handleKeyDown: (view, event) => {
                        if (
                            event.key === 'Backspace' ||
                            event.key === 'Delete'
                        ) {
                            view.state.deleting = true
                        }

                        return false
                    }
                },
                filterTransaction: (transaction, state) => {
                    let isDeleting = state.deleting

                    if (!state.deleting) {
                        return true
                    }

                    let result = true

                    transaction.mapping.maps.forEach(map => {
                        map.forEach((oldStart, oldEnd, newStart, newEnd) => {
                            state.doc.nodesBetween(
                                oldStart,
                                oldEnd,
                                (node, number, pos, parent, index) => {
                                    if (node.type.name === 'document') {
                                        result = false
                                    }
                                }
                            )
                        })
                    })

                    return result
                }
            })
        ]
    },

    addNodeView() {
        return VueNodeViewRenderer(DocumentComponent)
    }
})
