import { Mark, mergeAttributes } from '@tiptap/core'
import { updateMark, toggleMark } from 'tiptap-commands'

export const AnchorLink = Mark.create({
    name: 'anchorLink',
    content: 'block+',
    group: 'block',
    atom: true,

    addOptions() {
        return {
            HTMLAttributes: {}
        }
    },

    addAttributes() {
        return {
            hash: {
                default: this.options.HTMLAttributes.hash,
                parseHTML: (element) => {
                    return element.hasAttribute('data-hash') ? element.getAttribute('data-hash') : null
                }
            }
        }
    },

    parseHTML() {
        return [
            {
                tag: 'span.editor__accent'
            }
        ]
    },

    renderHTML({ HTMLAttributes }) {
        const elem = document.createElement('span')
        const attrs = Object.entries(
            mergeAttributes(
                { class: 'editor__accent' },
                { 'data-hash': HTMLAttributes.hash },
                HTMLAttributes,
                this.options.HTMLAttributes
            )
        )

        attrs.forEach(([attr, val]) => elem.setAttribute(attr, val))

        elem.addEventListener('click', (event) => {
            const target = event.target
            const anchorElem = document.getElementById(`${target.dataset.hash}`)

            anchorElem.scrollIntoView({ block: 'start' })
        })

        return elem
    },

    commands({ type }) {
        return attrs => {
            if (attrs.hash === null) {
                return toggleMark(type, attrs)
            }
            return updateMark(type, attrs)
        }
    }
})
